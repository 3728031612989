<template>
    <div id="app">
        <app-header />
        <div class="page-body">
            <router-view></router-view>
        </div>
        <app-footer />
    </div>
</template>

<script>
import appHeader from '../components/header'
import appFooter from '../components/footer'
export default {
    name: 'PcApp',
    components: {
        appHeader,
        appFooter
    },
    computed: {
        cpd_breadcrumb() {
            return this.$store.state.breadcrumb
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style lang="less">
@import "../less/pc.less";
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    display: flex;
    flex-direction: column;
    .page-body {
        width: 100%;
        background-image: url("../assets/images/pc/dian.png");
        background-repeat: repeat;
    }
}
</style>

<template>
    <header class="page-header">
        <div class="header-content">
            <div class="left">
                <router-link to="/"><img src="../../assets/images/pc/logo.png" /> </router-link>
            </div>
            <new-menu></new-menu>
            <div class="right">
                <wallet />
                <div class="lang" @click="toggleLang">
                    <img src="../../assets/images/lang.png" />
                    <span>{{cpd_lang}}</span>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { local } from "../../config/constant";
import wallet from "./wallet"

export default {
    name: 'Header',
    components: {
        wallet
    },
    data() {
        return {
            visible: false
        }
    },
    computed: {
        cpd_lang() {
            if (this.$i18n.locale === 'en-US') {
                return '中文'
            } else {
                return 'English'
            }
        }
    },
    methods: {
        toggleLang() {
            let v = this.$store.state.lang
            if (v === 'zh-CN') {
                v = 'en-US'
            } else {
                v = 'zh-CN'
            }
            this.$store.commit('setLang', v)
            this.$i18n.locale = v
            localStorage.setItem(local.LOCALE, v)
        }
    }
}
</script>

<style lang="less">
.page-header {
    display: flex;
    height: 60px;
    border-bottom: solid 1px #000;
    justify-content: center;
    .header-content {
        flex: 1;
        max-width: 1920px;
        display: flex;
        justify-content: space-between;
        .left {
            display: flex;
            padding-left: 36px;
            a {
                display: flex;
                align-items: center;
                img {
                    height: 60%;
                }
            }
        }
        .nav {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .nav-item {
                padding-left: 46px;
                span {
                    font-weight: bold;
                    font-family: ChalkboardBold;
                    cursor: pointer;
                    font-size: 16px;
                }
            }
        }
        .right {
            width: 370px;
            display: flex;
            justify-content: flex-end;
            .lang {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 24px;
                cursor: pointer;
                margin-left: 16px;
                span {
                    width: 62px;
                    padding-left: 6px;
                    font-size: 18px;
                }
            }
        }
    }
}
.header-wallet-dialog {
    padding: 0 24px;
    .address {
        background-color: #fff;
        border-radius: 13px;
        font-family: ChalkboardSEBold;
        font-size: 20px;
        line-height: 46px;
        text-align: center;
        color: #000;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        .button-item {
            display: flex;
            margin: 0 24px;
            a {
                display: flex;
            }
            span {
                font-size: 18px;
                color: #fff;
                display: flex;
                align-items: center;
                font-family: ChalkboardSEBold;
                padding-right: 12px;
            }
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
}
.custom-message-box {
    background-color: rgba(0, 0, 0, .7);
    border: none;
    border-radius: 13px;
    width: 220px;
    .custom-message {
        display: flex;
        flex-direction: column;
        div {
            display: flex;
            justify-content: center;
            img {
                width: 80px;
                height: 80px;
            }
        }
        span {
            font-size: 24px;
            color: rgba(255, 255, 255, .7);
            padding-top: 12px;
        }
    }
}
</style>
